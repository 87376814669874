import React from "react";

export default function WhatWeDoTab(props) {
  return (
    <div className="flex relative flex-row flex-wrap">
      <div className="w-full md:w-1/2">
        <img
          src={props.icons.image}
          className="transition duration-300 ease-in-out hover:scale-110 w-full p-4"
          alt=""
        />
      </div>
      <div className="w-full md:w-1/2">
        <div className="p-4">
          <img
            src="assets/catchup_logo_primary.png"
            className="w-[50%] mb-3"
            alt=""
          />
          <div className="font-semibold pb-7" style={{ fontSize: "20pt" }}>
            {props.data.header}
          </div>
          <div className="font-light">{props.data.text}</div>
        </div>
      </div>
    </div>
  );
}
