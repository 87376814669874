import Main from "pages/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "pages/Login";
import "index.css";
import "font.css";
import "assets/QuasimodaMedium.ttf";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
