import React, { useContext } from "react";
import { SiteContext } from "../SiteContextProvider";

const Slogan = () => {
  const { siteTexts } = useContext(SiteContext);
  return (
    <div className="flex flex-row flex-wrap items-center justify-between ">
      <div className="w-1/2">
        <img className="w-full" src="assets/slogan.png" alt="slogan" />
      </div>
      <div className="w-1/2 text-xl md:text-4xl text-center">
        <div className="mx-3">
          <span className="text-dark font-medium bg-light leading-relaxed ">
            {siteTexts.slogan.text}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slogan;
