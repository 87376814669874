import React from "react";
import InputComp from "./InputComp";
import axios from "axios";
import { useState, useContext } from "react";
import { SiteContext } from "../SiteContextProvider";

export default function ContactFormTab() {
  const { siteTexts } = useContext(SiteContext);
  const [messageStatus, setMessageStatus] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [institution, setInstitution] = useState("");
  const [message, setMessage] = useState("");

  const submitForm = async (e) => {
    e.preventDefault();
    const params = {
      id: new Date().getTime(),
      questionAbout: "CatchUpper Message",
      name: name + " - Phone: " + phone,
      companyName: institution,
      email: mail,
      tellUsMore: message,
    };

    try {
      await axios.post(
        "https://3dcpb80rz6.execute-api.eu-west-1.amazonaws.com/prod/contact-us",
        params
      );
      setMessageStatus(true);
    } catch (e) {
      console.log(e);
    }
  };

  return messageStatus === false ? (
    <form className="w-full">
      <div className="flex flex-wrap -mx-3 mb-6">
        <InputComp
          label={siteTexts.contactForm.name}
          placeholder={siteTexts.contactForm.name}
          col="w-full md:w-1/2 px-3"
          icon=""
          value={name}
          onChange={setName}
        />
        <InputComp
          label={siteTexts.contactForm.phone}
          placeholder={siteTexts.contactForm.phone}
          col="w-full md:w-1/2 px-3"
          icon=""
          value={phone}
          onChange={setPhone}
        />
        <InputComp
          label={siteTexts.contactForm.mail}
          placeholder={siteTexts.contactForm.mail}
          col="w-full md:w-1/2 px-3"
          icon=""
          value={mail}
          onChange={setMail}
        />
        <InputComp
          label={siteTexts.contactForm.institution}
          placeholder={siteTexts.contactForm.institution}
          col="w-full md:w-1/2 px-3"
          icon=""
          value={institution}
          onChange={setInstitution}
        />
        <InputComp
          label={siteTexts.contactForm.message}
          placeholder={siteTexts.contactForm.message}
          col="w-full px-3"
          icon=""
          value={message}
          onChange={setMessage}
        />
        <hr />
        <div className="w-full px-3 mt-6">
          <button
            type="button"
            disabled
            className=" bg-light text-white font-medium hover:bg-dark py-2 px-10 rounded-full"
            onClick={(e) => submitForm(e)}
          >
            {siteTexts.contactForm.buttom}
          </button>
        </div>
      </div>
    </form>
  ) : (
    <div className="py-16 bg-[#f5fafb]">
      <div className="container mx-auto h-full p-7 flex items-center justify-between">
        <div className="flex-1 w-[400px] p-7 text-dynamic font-bold">
          <span className="text-dark"> {siteTexts.contactForm.result1} </span>{" "}
          <span className="text-light">{siteTexts.contactForm.result2}</span>
        </div>
      </div>
    </div>
  );
}
