import React, { useContext } from "react";
import BannerText from "./BannerText";
import { NavHashLink } from "react-router-hash-link";
import { SiteContext } from "../SiteContextProvider";

export default function Banner() {
  const { siteTexts } = useContext(SiteContext);

  return (
    <div className="relative">
      <img src="assets/banner.jpg" alt="banner" />
      <div>
        <div className="absolute w-[45%] flex flex-col top-[10%] left-[5%] sm:top-[20%] md:top-[20%] md:left-[5%]">
          <div className="flex font-medium text-xl sm:text-3xl md:text-2xl lg:text-3xl">
            <p className="text-white">
              CatchUppers <span className="text-light">catch upper!</span>
            </p>
          </div>
          <div className="text-white pt-3 invisible md:visible">
            <p className="text-sm lg:text-base">{siteTexts.banner.brief}</p>
            <div className="mt-5">
              <NavHashLink
                to="#contact-form"
                className=" bg-light text-white font-medium hover:bg-dark py-2 px-10 rounded-full"
              >
                {siteTexts.banner.contactTextLabel}
              </NavHashLink>
            </div>
          </div>
        </div>
      </div>

      {siteTexts.banner.texts.map((data, key) => (
        <BannerText key={key} id={key} data={data} />
      ))}
      <div className="md:hidden p-5">
        <p className="text-base text-white bg-dark rounded p-4 lg:text-base">
          {siteTexts.banner.brief}
        </p>
      </div>
    </div>
  );
}
