import React, { Component, createContext } from "react";
const tr = require('./data/tr.json')
const en = require('./data/en.json')

export const SiteContext = createContext();

class SiteContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "tr",
      siteTexts: {...tr},
      activeTab: 0,
    };
    this.setLanguage = this.setLanguage.bind(this);
  }

  setLanguage = (language) => {
    this.setState({ language });
    if(language==="en"){
      this.setState({ siteTexts: en });
    }else{
      this.setState({ siteTexts: tr });
    }
  };


  setActiveTab = (activeTab) => {
    this.setState({ activeTab });
  };

  render() {
    return (
      <SiteContext.Provider
        value={{
          ...this.state,
          setLanguage: this.setLanguage,
          setActiveTab: this.setActiveTab,
        }}
      >
        {this.props.children}
      </SiteContext.Provider>
    );
  }
}

export default SiteContextProvider;
