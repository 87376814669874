import React from "react";

export default function FeatureBox(props) {
  return (
    <div
      className="cursor-pointer p-2 md:p-6 bg-[#ffffff] border border-gray-200 rounded-lg shadow grid grid-cols-1 content-center justify-items-center text-center transition duration-300 ease-in-out hover:scale-110 min-h-[120px] md:min-h-[200px]"
      style={{ margin: "5%" }}
      onClick={(e) => {
        e.stopPropagation();
        props.onClick(props.data.id);
      }}
    >
      <div className="my-2">
        <img
          className="h-10 w-10"
          src={props.featureIconList.src}
          alt="icon1"
        />
      </div>
      <div className="my-2">
        <p className="text-sm md:text-xl">{props.data.name}</p>
      </div>
    </div>
  );
}
