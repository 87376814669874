import React, { useContext } from "react";
import { SiteContext } from "../SiteContextProvider";
import { Link } from "react-router-dom";
import tr from "../assets/tr.png";
import en from "../assets/en.jpg";

export default function Header() {
  const { language, setLanguage } = useContext(SiteContext);

  const changeLang = (lang) => {
    setLanguage(lang);
  };

  return (
    <div className="container mx-auto flex flex-row items-center justify-between px-2 md:px-6 py-2 md:py-5">
      <div>
        <Link to="/">
          <img
            src="assets/catchup_logo_primary.png"
            alt="catchupper"
            className="h-6 md:h-12"
          />
        </Link>
      </div>
      <nav className="flex flex-row items-center gap-x-2 md:gap-x-8">
        {language === "tr" ? (
          <div className="cursor-pointer" onClick={() => changeLang("en")}>
            <img src={en} alt="en" className="w-10" />
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => changeLang("tr")}>
            <img src={tr} alt="tr" className="w-10" />
          </div>
        )}
        <div className="hidden lg:block">
          <a href="/login">
            <button className="bg-light text-white font-medium hover:bg-dark py-2 px-10 rounded-full">
              Giriş
            </button>
          </a>
        </div>
      </nav>
    </div>
  );
}
