import React, { useContext } from "react";
import { SiteContext } from "../SiteContextProvider";

export default function Video() {
  const { siteTexts } = useContext(SiteContext);
  return (
    <div
      className="grid place-content-center bg-no-repeat bg-auto bg-[#f5fafb]"
      style={{ backgroundImage: "url(assets/future_back.png)" }}
    >
      <div className="py-5 w-full md:w-[90%] m-auto">
        <video
          className="shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] rounded-2xl"
          style={{
            display: "block",
            verticalAlign: "middle",

            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          controls
        >
          <source src={siteTexts.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}
