import React from "react";
import Banner from "components/Banner";
import ContactForm from "components/ContactForm";
import Features from "components/Features";
import Footer from "components/Footer";
import Header from "components/Header";
import ScreenShot from "components/ScreenShot";
import Slogan from "components/Slogan";
import WhatWeDo from "components/WhatWeDo";
import Video from "components/Video";

export default function Main() {
  return (
    <>
      <Header />
      <div className="container mx-auto">
        <Banner />
        <Slogan />
        <Video />
        <WhatWeDo />
        <Features />
        <ScreenShot />
        <ContactForm />
      </div>
      <Footer />
    </>
  );
}
