import React, { useContext } from "react";
import { SiteContext } from "../SiteContextProvider";
import WhatWeDoTab from "./WhatWeDoTab";
import icon_dijital_egitim_2 from "../assets/1_icon_dijital_egitim_2.png";
import icon_dijital_egitim from "../assets/1_icon_dijital_egitim.png";
import icon_kisisel_ogrenim_2 from "../assets/2_icon_kisisel_ogrenim_2.png";
import icon_kisisel_ogrenim from "../assets/2_icon_kisisel_ogrenim.png";
import kisisel_ogretim from "../assets/2_kisisel_ogretim.png";
import icon_ozgun_ogretim_2 from "../assets/3_icon_ozgun_ogretim_2.png";
import icon_ozgun_ogretim from "../assets/3_icon_ozgun_ogretim.png";
import ozgun_ogretim from "../assets/3_ozgun_ogretim.png";
import hizli_olcme from "../assets/4_hizli_olcme.png";
import icon_hizli_olcme_2 from "../assets/4_icon_hizli_olcme_2.png";
import icon_hizli_olcme from "../assets/4_icon_hizli_olcme.png";
import detayli_analiz from "../assets/5_detayli_analiz.png";
import icon_detayli_analiz_2 from "../assets/5_icon_detayli_analiz_2.png";
import icon_detayli_analiz from "../assets/5_icon_detayli_analiz.png";
import dijital_ogrenme from "../assets/1_dijital_ogrenme.png";

const tabIcons = [
  {
    icon: icon_dijital_egitim,
    icon2: icon_dijital_egitim_2,
    image: dijital_ogrenme,
  },
  {
    icon: icon_kisisel_ogrenim,
    icon2: icon_kisisel_ogrenim_2,
    image: kisisel_ogretim,
  },
  {
    icon: icon_ozgun_ogretim,
    icon2: icon_ozgun_ogretim_2,
    image: ozgun_ogretim,
  },
  {
    icon: icon_hizli_olcme,
    icon2: icon_hizli_olcme_2,
    image: hizli_olcme,
  },
  {
    icon: icon_detayli_analiz,
    icon2: icon_detayli_analiz_2,
    image: detayli_analiz,
  },
];

export default function WhatWeDo() {
  const { siteTexts, activeTab, setActiveTab } = useContext(SiteContext);

  return (
    <div
      id="what-we-do"
      className="w-full mb-10"
      style={{
        backgroundImage: "url(assets/what_back.png)",
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="p-2 sm:p-5 md:p-10 text-white font-semibold">
        <div className="text-white font-medium text-xl md:text-4xl mb-3 md:mb-10">
          {siteTexts.whatWeDo.header}
        </div>
        <div className="flex flex-row justify-center flex-wrap">
          {siteTexts.whatWeDo.tabs.map((tab, key) => (
            <div
              key={key}
              className={`flex flex-row items-center rounded-lg md:rounded-t-lg border-gray-900 cursor-pointer mx-1 md:mx-2 md:w-[200px] ${
                activeTab === key ? "bg-white text-dark" : ""
              }`}
              onClick={() => setActiveTab(key)}
            >
              <div className="p-2 mb-2 md:mb-0">
                <img
                  className="h-6 w-6 sm:h-8 sm:w-8 md:h-10 md:w-10"
                  src={
                    activeTab === key ? tabIcons[key].icon : tabIcons[key].icon2
                  }
                  alt="icon3"
                />
              </div>
              <div className="flex-1 hidden md:block">
                <p className="p-4">{tab.name}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-white text-dark p-4 sm:p-8 md:p-14 rounded-2xl border-2 border-slate-800 -mt-2">
          <WhatWeDoTab
            data={siteTexts.whatWeDo.tabs[activeTab]}
            icons={tabIcons[activeTab]}
          />
        </div>
      </div>
    </div>
  );
}
