import React, { useState, useEffect, useContext } from "react";
import { SiteContext } from "../SiteContextProvider";

export default function ScreenShot() {
  const { siteTexts } = useContext(SiteContext);
  const [selected, setSelected] = useState(0);
  const [currentOpacity, setCurrentOpacity] = useState(0);
  let timer;
  let currentOpacityCount = 0;
  let opacityTimer;

  const updateCount = () => {
    timer = setInterval(() => {
      if (selected === 3) setSelected(0);
      else setSelected((prevCount) => prevCount + 1);
    }, 3000);
  };

  useEffect(() => {
    setCurrentOpacity(1);
    currentOpacityCount = 1;

    opacityTimer = setInterval(() => {
      currentOpacityCount -= 0.025;
      setCurrentOpacity(currentOpacityCount);
    }, 50);

    return () => {
      if (opacityTimer) {
        clearInterval(opacityTimer);
      }
    };
  }, [selected]);

  useEffect(() => {
    updateCount();

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className="bg-[#f5fafb] py-8">
      <div className="container h-full flex flex-row flex-wrap items-center justify-between pb-16">
        <div>
          <img className="w-full" src="assets/slogan2.png" alt="slogan" />
        </div>
        <div className="relative flex-1 text-dynamic font-bold text-center">
          <div className="absolute left-0 right-0 top-0 bottom-0">
            <span className="text-light" style={{ opacity: currentOpacity }}>
              {siteTexts.screen.slogans[selected].text}
            </span>
          </div>
          <div className="absolute left-0 right-0 top-0 bottom-0 ">
            <span
              className="text-light"
              style={{ opacity: 1 - currentOpacity }}
            >
              {siteTexts.screen.slogans[(selected + 1) % 4].text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
