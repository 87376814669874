import React, { useContext } from "react";
import { SiteContext } from "../SiteContextProvider";

import loginAdmin from "../assets/login_admin.png";
import loginUser from "../assets/login_user.png";
import { Link } from "react-router-dom";

export default function Login() {
  const { siteTexts } = useContext(SiteContext);
  return (
    <div
      className="container mx-auto h-screen"
      style={{
        backgroundImage: `url("../assets/login_background.jpg")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="h-full flex flex-col md:flex-row items-center justify-center py-20">
        <div className="w-full md:w-1/2 flex flex-col items-center">
          <div className="w-[200px] h-[200px]">
            <Link to="https://admin.catchupper.com" target="_blank">
              <img className="w-full" src={loginAdmin} alt="" />
            </Link>
          </div>
          <div className="text-center text-white text-4xl ">
            {siteTexts.login.admin}
          </div>
        </div>

        <div className="hidden md:block border-2 h-[300px]"></div>

        <div className="w-full md:w-1/2 flex flex-col items-center">
          <div className="w-[200px] h-[200px]">
            <Link to="https://app.catchupper.com" target="_blank">
              <img className="w-full" src={loginUser} alt="" />
            </Link>
          </div>
          <div className="text-center text-white text-4xl ">
            {siteTexts.login.learner}
          </div>
        </div>
      </div>
    </div>
  );
}
