import React, { useState, useContext } from "react";
import FeatureBox from "./FeatureBox";
import { SiteContext } from "../SiteContextProvider";
import future_icon_1 from "../assets/future_icon_1.png";
import future_icon_1h from "../assets/future_icon_1h.png";
import future_icon_2 from "../assets/future_icon_2.png";
import future_icon_2h from "../assets/future_icon_2h.png";
import future_icon_3 from "../assets/future_icon_3.png";
import future_icon_3h from "../assets/future_icon_3h.png";
import future_icon_4 from "../assets/future_icon_4.png";
import future_icon_4h from "../assets/future_icon_4h.png";
import future_icon_5 from "../assets/future_icon_5.png";
import future_icon_5h from "../assets/future_icon_5h.png";
import future_icon_6 from "../assets/future_icon_6.png";
import future_icon_6h from "../assets/future_icon_6h.png";
import future_icon_7 from "../assets/future_icon_7.png";
import future_icon_7h from "../assets/future_icon_7h.png";

const divStyles = {
  boxShadow:
    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
};

const featureIconList = [
  {
    src: future_icon_1,
    src2: future_icon_1h,
  },
  {
    src: future_icon_2,
    src2: future_icon_2h,
  },
  {
    src: future_icon_3,
    src2: future_icon_3h,
  },
  {
    src: future_icon_4,
    src2: future_icon_4h,
  },
  {
    src: future_icon_5,
    src2: future_icon_5h,
  },
  {
    src: future_icon_6,
    src2: future_icon_6h,
  },
  {
    src: future_icon_7,
    src2: future_icon_7h,
  },
];

export default function Features() {
  const { siteTexts } = useContext(SiteContext);
  const [visibility, setVisibility] = useState(false);
  const [selected, setSelected] = useState(null);
  // const [oldSelected, setOldSelected] = useState(null);

  const show = (id) => {
    if (id !== null) {
      setSelected(id);
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundImage: "url(assets/zem1.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      >
        <div
          onClick={() => {
            show(null);
          }}
          className="bg-no-repeat bg-auto relative p-2 sm:p-5 md:p-10  font-semibold"
          style={{ backgroundImage: "url(assets/future_back.png)" }}
        >
          <div className="font-medium text-xl text-right md:text-4xl mb-3 md:mb-3">
            {siteTexts.features.header}
          </div>
          <div className="flex flex-col items-center">
            {visibility === true ? (
              <div
                className="flex flex-col md:flex-row items-center rounded-lg gap-2 md:gap-8 w-[90%] md:w-[80%] p-2 md:p-6 rounded-2 bg-gray-50 content-center my-2 md:my-8"
                style={divStyles}
              >
                <img
                  className="h-20 md:h-32"
                  src={`${featureIconList[selected].src}`}
                  alt="detail"
                />
                <div className="flex-1 flex flex-col justify-center py-2 md:py-5">
                  <p className="text-sm md:text-xl text-center">
                    {selected !== null
                      ? siteTexts.features.featureList[selected].text
                      : null}
                  </p>
                </div>
              </div>
            ) : null}

            <div className="grid grid-cols-3">
              <div className="grid content-start">
                <div className="h-[75px]"></div>
                <FeatureBox
                  data={siteTexts.features.featureList[0]}
                  featureIconList={featureIconList[0]}
                  onClick={show}
                />
                <FeatureBox
                  data={siteTexts.features.featureList[1]}
                  featureIconList={featureIconList[1]}
                  onClick={show}
                />
              </div>
              <div className="grid content-start ">
                <FeatureBox
                  data={siteTexts.features.featureList[2]}
                  featureIconList={featureIconList[2]}
                  onClick={show}
                />
                <FeatureBox
                  data={siteTexts.features.featureList[3]}
                  featureIconList={featureIconList[3]}
                  onClick={show}
                />
                <FeatureBox
                  data={siteTexts.features.featureList[6]}
                  featureIconList={featureIconList[6]}
                  onClick={show}
                />
              </div>
              <div className="grid content-start">
                <div className="h-[75px]"></div>
                <FeatureBox
                  data={siteTexts.features.featureList[4]}
                  featureIconList={featureIconList[4]}
                  onClick={show}
                />
                <FeatureBox
                  data={siteTexts.features.featureList[5]}
                  featureIconList={featureIconList[5]}
                  onClick={show}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
