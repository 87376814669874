import React, { useContext } from "react";
import { SiteContext } from "../SiteContextProvider";
import { NavHashLink } from "react-router-hash-link";

export default function BannerText(props) {
  const { setActiveTab } = useContext(SiteContext);

  const changeActiveTabId = () => {
    setActiveTab(props.id);
  };

  return (
    <div
      className="md:absolute text-light hidden lg:block text-sm lg:text-base"
      style={{
        // width: props.data.width,
        position: "position",
        left: props.data.left,
        top: props.data.top,
        right: props.data.right,
        bottom: props.data.bottom,
      }}
    >
      <NavHashLink to="/#what-we-do" onClick={changeActiveTabId}>
        <p
          className="border text-center rounded-full p-2"
          style={{
            backgroundColor: "white",
          }}
        >
          {props.data.text}
        </p>
      </NavHashLink>
    </div>
  );
}
