import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div
      className="container mx-auto text-sm md:text-base"
      style={{
        backgroundImage:
          "url(assets/footer_shape.png),linear-gradient(to right, #114859 , #2D8590)",
        backgroundSize: "left top, cover",
        backgroundRepeat: "no-repeat, repeat",
      }}
    >
      <div className="px-2 sm:px-5 md:px-8">
        <div className="flex items-center justify-between p-2 sm:px-5 md:px-8">
          <div>
            <img
              src="assets/logo_primary.png"
              alt="catchupper"
              className="h-10"
            />
            <div className="text-gray-50 py-4">
              <p>
                Bilkent CYBERPARK, Cyberplaza H Blok Kat: 1 No:8, Bilkent
                ANKARA, Türkiye <br />
              </p>
              <p>+(90) 312 265 04 56</p>
              <br />
              <p>©2023 Genixo, Inc. All Rights Reserved.</p>
              <br />
              <p className="cursor-pointer">Privacy Policy</p>
            </div>
          </div>
          <div>
            <div className="text-gray-50 py-4">
              <a href="mailto:hello@catchupper.com">hello@catchupper.com</a>
            </div>
            <div className="flex gap-x-4 text-sm font-thin">
              <Link
                to="https://www.linkedin.com/company/catchupper"
                target="_blank"
              >
                <img
                  src="assets/linkedin.png"
                  alt="catchupper"
                  className="h-[28px]"
                />
              </Link>
              <Link
                to="https://www.instagram.com/hellocatchupper/"
                target="_blank"
              >
                <img
                  src="assets/instagram.png"
                  alt="catchupper"
                  className="h-[28px]"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
