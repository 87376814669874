import React from "react";
import ContactFormTab from "./ContactFormTab";

export default function ContactForm() {
  return (
    <div className="py-12 bg-[#f5fafb]" id="contact-form">
      <div className="block p-2 md:p-6  bg-[#ffffff] border border-gray-200 rounded-lg shadow mx-2 sm:mx-5 md:mx-12">
        <ContactFormTab />
      </div>
    </div>
  );
}
